import React, { useState } from 'react'
import './MainPageGallery.css'
import { ClipLoader } from 'react-spinners';

interface Props {
  mainPageImages: string[];
}

export default function MainPageGallery(props: Props) {
  const [leftImageLoaded, setLeftImageLoaded] = useState<boolean>(false); 
  const [centreImageLoaded, setCentreImageLoaded] = useState<boolean>(false); 
  const [rightImageLoaded, setRightImageLoaded] = useState<boolean>(false); 


  return (
    <div className='gallery-container'>
      <img src={props.mainPageImages[1]} height={"400px"} style={{display: "block", visibility: leftImageLoaded && centreImageLoaded && rightImageLoaded ? "visible" : "hidden"}}   onLoad={() => { setLeftImageLoaded(true);}}/>
      <img src={props.mainPageImages[0]} height={"400px"} style={{display: "block", visibility: leftImageLoaded && centreImageLoaded && rightImageLoaded ? "visible" : "hidden"}} onLoad={() => { setCentreImageLoaded(true);}}/>
      <img src={props.mainPageImages[2]} height={"400px"} style={{display: "block", visibility: leftImageLoaded && centreImageLoaded && rightImageLoaded ? "visible" : "hidden"}}  onLoad={() => { setRightImageLoaded(true);}}/>
      <ClipLoader
        color={"red"}
        loading={!(leftImageLoaded && centreImageLoaded && rightImageLoaded)}
        cssOverride={{
        position: "fixed",
        margin: "5em auto",
        }}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}
