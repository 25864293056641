import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "./firebaseConfig";
import { FirebaseImageData } from "./models/FirebaseImageData";

const loadListOfImages = async (folderPath: string) => {
  // console.log("Retrieving images");
  // Create a reference to the folder in Firebase Storage
  const folderRef = ref(storage, folderPath);

  // List all items (files) in the folder
  const res = await listAll(folderRef);
    
  // Get download URLs for each file
  const imageUrls: FirebaseImageData[] = await Promise.all(
    res.items.map(async (itemRef) => {
      const url = await getDownloadURL(itemRef);
      // console.log("Image url: ", itemRef.fullPath, url);
      const imgData: FirebaseImageData = { filePath: itemRef.fullPath, publicImageUrl: url};
      return imgData;
    })
  );
  
  return imageUrls;
};

const loadImage = async (filePath: string): Promise<FirebaseImageData> => {
  try {
    // Create a reference to the file in Firebase Storage
    const fileRef = ref(storage, filePath);  // Correct way to reference a file by path

    // Get the download URL
    const url = await getDownloadURL(fileRef);
    const data: FirebaseImageData = { filePath: fileRef.fullPath, publicImageUrl: url};
    return data;
  } catch (error) {
    console.error("Error retrieving image URL:", error);
    throw new Error("Failed to retrieve image URL.");
  }
};


export const firebaseStorage = { loadListOfImages, loadImage }