import React, { useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet';
import BucketList from '../BucketList/BucketList';
import Milestone from '../../models/Milestone';
import Timeline from '../Timeline';

export default function TestPage() {
  const [openTimeline, setOpenTimeline] = useState<boolean>(false);
  const [openBucketList, setOpenBucketList] = useState<boolean>(false);

  return (
    <div>
      <h1>Test Page</h1>
      <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
        <button onClick={() => { setOpenTimeline(!openTimeline); }}>Open bottom sheet</button>
        <button onClick={() => { setOpenBucketList(!openBucketList); }}>Open bucket list</button>
      </div>
      <BottomSheet open={openBucketList} snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight - 20]}
        onDismiss={() => { setOpenBucketList(false); }}
      >
        <BucketList/>
      </BottomSheet>
    </div>
  )
}
