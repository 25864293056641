import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import MainPage from './components/MainPage/MainPage';
import LoginPage from './components/LoginPage/LoginPage';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USER } from './features/appSlice';
import { RootState } from './store';
import { ToastContainer } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TestPage from './components/TestPage/TestPage';
import { auth, firebaseConfig } from './firebaseConfig';

export default function App() {
  const dispatcher = useDispatch();

  const user = useSelector((state: RootState) => state.app.user);
  
  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // console.log("Change in auth state:", user);
      dispatcher(SET_USER(user));  // user is the logged-in user object, or null if not logged in
    });

    return () => unsubscribe(); // Cleanup subscription on unmount

  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={user !== null ? <MainPage/> : <Navigate to="/login"/>}/>
        <Route path="/login" element={user !== null ? <Navigate to="/main" /> : <LoginPage />} />
        <Route path="*" element={user !== null ? <MainPage /> : <LoginPage />} />
        <Route path='/test' element={<TestPage/>}/>
      </Routes>
      <ToastContainer/>
    </Router>
  )
}
