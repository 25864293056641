import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { SET_SELECTED_MILESTONE } from '../../features/appSlice';
import { FirebaseImageData, findImageInList } from '../../models/FirebaseImageData';
import Milestone from '../../models/Milestone';
import './ImagesTimelineTemplate.css'
import { Carousel } from 'react-bootstrap';

interface Props {
  milestone: Milestone;
  loadedImages: FirebaseImageData[];
}

export default function ImagesTimelineTemplate(props: Props) {
  const dispatcher = useDispatch();

  const [imageUrls, setImageUrls] = useState<string[]>([]);

  const [leftImageLoaded, setLeftImageLoaded] = useState<boolean>(false); 
  const [centreImageLoaded, setCentreImageLoaded] = useState<boolean>(false); 
  const [rightImageLoaded, setRightImageLoaded] = useState<boolean>(false); 

  function setSelectedMilestone() {
    dispatcher(SET_SELECTED_MILESTONE(props.milestone));
  }

  useEffect(() => {
    let imageUrls = [];
    imageUrls.push(findImageInList(props.loadedImages, props.milestone.leftImageSrc)?.publicImageUrl ?? "");
    imageUrls.push(findImageInList(props.loadedImages, props.milestone.centreImageSrc)?.publicImageUrl ?? "");
    imageUrls.push(findImageInList(props.loadedImages, props.milestone.rightImageSrc)?.publicImageUrl ?? "");

    setImageUrls(imageUrls);
  }, [props.loadedImages]);

  return (
    <div style={{minHeight: "280px", display: "flex", flexDirection: "column", gap: "5px"}}>
      <div>
        <h1 className='CardTitle-sc-d7qjm1-3 jafwdL rc-card-title'>{props.milestone.title}</h1>
      </div>
      <div className="images-container">
        <img src={imageUrls[0]} alt='Left-image'  className='side-image left-image'  onClick={setSelectedMilestone} height={"175px"} width={"130px"} style={{visibility: leftImageLoaded && centreImageLoaded && rightImageLoaded ? "visible" : "hidden"}} onLoad={() => { setLeftImageLoaded(true);}}/>
        <img src={imageUrls[1]} alt='Center-image' onClick={setSelectedMilestone} height={"175px"} width={"160px"} style={{objectFit: "cover", visibility: leftImageLoaded && centreImageLoaded && rightImageLoaded ? "visible" : "hidden"}}               onLoad={() => { setCentreImageLoaded(true);}}/>
        <img src={imageUrls[2]} alt='Right-image' className='side-image right-image' onClick={setSelectedMilestone} height={"175px"} width={"130px"} style={{visibility: leftImageLoaded && centreImageLoaded && rightImageLoaded ? "visible" : "hidden"}} onLoad={() => { setRightImageLoaded(true);}}/>
      </div>
      <span style={{fontSize: "16px", textAlign: "center"}}>
        { props.milestone.subtitle !== undefined && props.milestone.subtitle !== "" && props.milestone.subtitle}
      </span>
    </div>
  )
}
