import { faCircle, faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import './BucketList.css';
import BucketListItemData from '../../models/BucketListItemData';

interface Props {
  data: BucketListItemData;
  markedAsComplete: () => void;
}

export default function BucketListItem(props: Props) {
  const [hoverCheckButton, setHoverCheckButton] = useState<boolean>(false);

  function getIcon() {
    if (props.data.completed) {
      return faCircleXmark;
    }
    else if (hoverCheckButton) {
      return faCircleCheck;
    }
    else {
      return faCircle;
    }
  }

  return (
    <div className="bucketlist-item-container" style={{color: !props.data.completed && hoverCheckButton ? "green" : "black"}}>
      <div style={{fontSize: "large"}} 
        onMouseEnter={() => { setHoverCheckButton(true); }} 
        onMouseLeave={() => { setHoverCheckButton(false);}}
        onClick={() => {
          if (!props.data.completed) {
            props.markedAsComplete();
          }
        }}
      >
        <FontAwesomeIcon icon={getIcon()}/>
      </div>
      <span className='bucketlist-item-text' style={{
        textDecoration: props.data.completed ? "line-through": "", 
        fontWeight: !props.data.completed && hoverCheckButton ? "bold" : "", 
        width: props.data.completed ? "218px" : "",
        maxWidth: props.data.completed ? "218px" : ""}}
      >{props.data.description}</span>
      {
        props.data.dateCompleted &&
        <span style={{textAlign: "end"}}>({props.data.dateCompleted})</span>
      }
    </div>
  )
}
