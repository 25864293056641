import React, { useEffect, useRef, useState } from 'react'
import { Chrono } from 'react-chrono'
import Milestone from '../models/Milestone';
import { findImageInList, FirebaseImageData } from '../models/FirebaseImageData';
import ImagesTimelineTemplate from './timelineTemplates/ImagesTimelineTemplate';

interface Props {
  milestones: Milestone[];
  timelineImageData: FirebaseImageData[];
}

export default function Timeline(props: Props) {

  const containerRef = useRef<HTMLDivElement>(null);

  const [content, setContent] = useState<JSX.Element[]>([]);

  useEffect(() => {
    if (props.timelineImageData.length === 0 || props.milestones.length === 0) {
      return;
    }
    else {
      const content = props.milestones.map((milestone, index) => (
        <div key={index}>
          <ImagesTimelineTemplate 
            milestone={milestone} loadedImages={props.timelineImageData}          
          />
        </div>
      ));
      setContent(content);
    }
  }, [props.timelineImageData, props.milestones]);  

  return (
    <div ref={containerRef} style={{backgroundColor: "#ADD8E6"}}>
      <div style={{backgroundColor: "#f07167"}}>
        <h1 style={{color: "white", textAlign: "center", fontSize: "1.5em", padding: "0.5em"}}>A Year In Review...</h1>
      </div>
      <Chrono 
  
        items={props.milestones.map((item) => { return ( {title: item.date } )})} 
        mode="VERTICAL_ALTERNATING" 
        theme={{
          primary: "#f07167",
          secondary: "#ffffff",
          titleColor: "#f07167"
        }}
        disableToolbar={true}
      >
        {content}
      </Chrono>
      <div style={{marginBottom: "150px"}}>
        <h1 style={{color: "#f07167", textAlign: "center", fontSize: "1.5em", padding: "0.5em", fontWeight: "bold"}}>And Many More Happy Memories To Come!...</h1>
      </div>
    </div>
  )
}
