import React, { useEffect, useRef, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css'
import Milestone from '../../models/Milestone';
import Confetti from 'react-confetti'
import Timeline from '../../components/Timeline';
import { auth, db, signOut, storage } from '../../firebaseConfig';
import { firebaseStorage } from '../../firebaseStorage';
import { useDispatch } from 'react-redux';
import { SET_ERROR_MESSAGE, SET_USER } from '../../features/appSlice';
import { addDoc, collection, getDocs } from 'firebase/firestore';
import BucketList from '../BucketList/BucketList';
import './MainPage.css';
import { FirebaseImageData } from '../../models/FirebaseImageData';
import { ClipLoader } from 'react-spinners';
import MainPageGallery from '../MainPageGallery/MainPageGallery';

export default function MainPage() {
    const [openTimeline, setOpenTimeline] = useState<boolean>(false);
    const [openBucketList, setOpenBucketList] = useState<boolean>(false);
  
    const [showConfetti, setShowConfetti] = useState<boolean>(true);
    const [enableButtons, setEnableButtons] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const [mainPageImages, setMainPageImages] = useState<string[]>([]);
    const [timelineItems, setTimelineItems]     = useState<Milestone[]>([]);
    const [timelineImages, setTimelineImages]   = useState<FirebaseImageData[]>([]);

    const dispatcher = useDispatch();
  
    useEffect(() => {
      const loadAllData = async() => {
        try {
          await Promise.all([
            loadFrontPageImages(),
            loadTimelineData(),
            loadTimelineImages()
          ]);
        } catch(error) {
          console.error("Error loading data:", error);
        }
        finally {
          setLoading(false);
        }
      }
      setTimeout(() => { 
        setShowConfetti(false);
        setEnableButtons(true);
      }, 5 * 1000);
      void loadAllData();
    }, []);

    async function loadTimelineImages() {
      var imageData = await firebaseStorage.loadListOfImages("timeline");
      setTimelineImages(imageData);
    }

    async function loadFrontPageImages() {
      try {
        const imageData = await firebaseStorage.loadListOfImages("front-page");
        if (imageData !== undefined) {
          setMainPageImages(imageData.map((row) => ( row.publicImageUrl )));
        }
      }
      catch(error) {
        dispatcher(SET_ERROR_MESSAGE("Error Unauthorised User"));
        dispatcher(SET_USER(null));
      }
    }

    async function handleSignOut() {
      try {
        await signOut(auth);  // Signs the user out
        // console.log("User signed out successfully");
      } catch (error) {
        // console.error("Error signing out: ", error);
      }
    };

    async function loadTimelineData() {
      try {
        const querySnapshot = await getDocs(collection(db, "timelinedata"));
        let itemsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as Milestone[];
        itemsList = itemsList.sort((a, b) => {
          return a.order - b.order;
        });
        // console.log(itemsList);


        setTimelineItems(itemsList);
      } catch (error) {
        // console.error("Error getting documents: ", error);
      } finally {
      }
    }
    if (loading) {
      return (
        <div>
          <h1 style={{fontSize: "x-large"}}>Loading...</h1>
        </div>
      )
    }
    
    return (

      <div>
        {
          showConfetti && 
          <Confetti run={showConfetti} recycle={false} numberOfPieces={400}
            drawShape={ctx => {
              const colours = [
                "#fc0303", "#fd4242", "#bd0202", 
                "#fd5656", "#fd8181", "#fea9a9",
                "#a90202", "#7e0202", "#560101"
              ]
  
              const x = 0;
              const y = 0;
              const height = 25;
              const width = 25; 
              const color = colours[Math.floor(Math.random() * colours.length)]
  
              ctx.beginPath();
              var topCurveHeight = height * 0.3;
              ctx.moveTo(x, y + topCurveHeight);
              // top left curve
              ctx.bezierCurveTo(
                x, y, 
                x - width / 2, y, 
                x - width / 2, y + topCurveHeight
              );
  
              // bottom left curve
              ctx.bezierCurveTo(
                x - width / 2, y + (height + topCurveHeight) / 2, 
                x, y + (height + topCurveHeight) / 2, 
                x, y + height
              );
  
              // bottom right curve
              ctx.bezierCurveTo(
                x, y + (height + topCurveHeight) / 2, 
                x + width / 2, y + (height + topCurveHeight) / 2, 
                x + width / 2, y + topCurveHeight
              );
  
              // top right curve
              ctx.bezierCurveTo(
                x + width / 2, y, 
                x, y, 
                x, y + topCurveHeight
              );
  
              ctx.closePath();
              ctx.fillStyle = color;
              ctx.fill();
            }}
          />
        }
        <div style={{display: "flex", flexDirection: "column", gap: "10px"}}>
          <div style={{display: "flex", flexDirection: "column", margin: "auto", justifyContent: "center"}}>
            <h1 style={{fontSize: "50px", color: "#f07167", margin: 0, textAlign: "center"}}>Jen + Matt</h1>
            <sub style={{fontSize: "20px", color: "#f07167", marginTop: "0.5em", textAlign: "center"}}>365 Days of loving you</sub>
            <img src='images/section-divider-pink.png' height={"60px"} width={'400px'} style={{marginTop: "20px"}}/>
          </div>
          <MainPageGallery mainPageImages={mainPageImages}/>
          <div className='buttons-container'>
            <button disabled={!enableButtons} className="section-button" style={{width: "100%"}} onClick={() => setOpenTimeline(true)}>Timeline 📷✨</button>
            <button disabled={!enableButtons} className="section-button" style={{width: "100%"}} onClick={() => setOpenBucketList(true)}>Bucket List 🪣📋</button>
            <button className='section-button' style={{width: "100%"}} onClick={handleSignOut}>Log out</button>
          </div>
    
          <BottomSheet open={openTimeline} snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight - 25]}
            onDismiss={() => { setOpenTimeline(false); }}
          >
            <Timeline milestones={timelineItems} timelineImageData={timelineImages}/>
          </BottomSheet>
          <BottomSheet open={openBucketList} snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight - 25]}
            onDismiss={() => { setOpenBucketList(false); }}
          >
            <BucketList/>
          </BottomSheet>
        </div>
      </div>
      
    );
}
