import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import Milestone from "../models/Milestone"

export interface AppState {
    selectedMilestone: Milestone | undefined;
    user: any | null;
    errorMessage: string;
}

const initialState: AppState = {
    selectedMilestone: undefined,
    user: undefined,
    errorMessage: ""
}

export const appSlice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        SET_SELECTED_MILESTONE: (state, action: PayloadAction<Milestone | undefined>) => {
            state.selectedMilestone = action.payload
        },
        SET_USER: (state, action: PayloadAction<any | null>) => {
            state.user = action.payload;
        },
        SET_ERROR_MESSAGE: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        }
    }
});

export const {
    SET_SELECTED_MILESTONE, SET_USER, SET_ERROR_MESSAGE
} = appSlice.actions;

export default appSlice.reducer;