import React, { useEffect, useState } from 'react'
import './LoginPage.css'
import { browserLocalPersistence, onAuthStateChanged, setPersistence, signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../../firebaseConfig';
import GoogleButton from 'react-google-button';
import { useDispatch, useSelector } from 'react-redux';
import { SET_USER } from '../../features/appSlice';
import { toast, ToastContainer } from 'react-toastify';
import { RootState } from '../../store';


interface Props {
  
}


export default function LoginPage(props: Props) {
  const dispatcher = useDispatch();

  const errorMessage = useSelector((state: RootState) => state.app.errorMessage);

  // Ensure local persistence when the component mounts
  useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        // console.log("Persistence set to local.");
      })
      .catch((error) => {
        // console.error("Failed to set persistence:", error);
      });

    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      
    });

    return () => unsubscribe(); // Clean up the subscription on unmount
  }, []);

  useEffect(() => {
    if (errorMessage !== "") {
      toast(errorMessage);
    }
  }, [errorMessage]);

  async function handleLogin() {
    try {
      const result = await signInWithPopup(auth, provider);
      dispatcher(SET_USER(result.user));
    } catch (error) {
    }
  }

  return (
    <div>
      {/* Login Form */}
      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", gap: "12px", margin: "50px auto", width: "360px"}}>
        <h1 style={{textAlign: "center", color: "#f07167", fontSize: "4em"}}>Login</h1>
        <div id='google-button-wrapper'>
          <GoogleButton onClick={handleLogin} id='login-button'/>
        </div>
      </div>
    </div>
  )
}
