import React, { useEffect, useState } from 'react'
import './BucketList.css';
import { collection, addDoc, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import BucketListItemData from '../../models/BucketListItemData';
import BucketListItem from './BucketListItem';

export default function BucketList() {
  const [bucketListItem, setBucketListItem] = useState<string>("");
  const [bucketListItems, setBucketListItems] = useState<BucketListItemData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  function formatDate(date: Date) {
    date = new Date(date);

    if (date === null || date === undefined) return "";
    const dateString = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
    return dateString;
  }

  async function addBucketListItem() {
    try {
      const docRef = await addDoc(collection(db, "bucketlist"), {
        description: bucketListItem,
        completed: false,
        dateCompleted: null
      });
      // console.log("Document written with ID: ", docRef.id);
      setBucketListItem("");
      await getBucketListItems();
    }
    catch (error) {
      console.error("Error adding document: ", error);
    }
  }

  async function getBucketListItems() {
    try {
      const querySnapshot = await getDocs(collection(db, "bucketlist"));
      const itemsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as BucketListItemData[];
      setBucketListItems(itemsList);
    } catch (error) {
      console.error("Error getting documents: ", error);
    } finally {
      setLoading(false);
    }
  }

  async function markBucklistItemAsComplete(itemId: string) {
    setLoading(true);
    try {
      const itemRef = doc(db, "bucketlist", itemId);
      await updateDoc(itemRef, {
        completed: true,
        dateCompleted: formatDate(new Date())
      });
      await getBucketListItems();
    }
    catch(error) {
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    void getBucketListItems();
  }, []);

  return (
    <div style={{backgroundColor: "#ADD8E6", height: "100%"}}>
      <div style={{backgroundColor: "#f07167"}}>
        <h1 style={{color: "white", textAlign: "center", fontSize: "1.5em", padding: "0.5em"}}>Bucket List 🪣📋</h1>    
      </div>
      <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
        <div className="bucket-list-container">
          <h2>Complete</h2>
          {
            bucketListItems.filter(i => i.completed === true).map((item, index) => 
              <BucketListItem key={index} data={item} markedAsComplete={() => { markBucklistItemAsComplete(item.id); }}/>
            )
          }
          
        </div>
        <div className="bucket-list-container">
          <h2>To Do</h2>
          {
            bucketListItems.filter(i => i.completed === false).map((item, index) => 
              <BucketListItem key={index} data={item} markedAsComplete={() => { markBucklistItemAsComplete(item.id); }}/>
            )
          }
          
        </div>
        {/* Add button section */}
        <div className="bucket-list-container">
          <input type='text' value={bucketListItem} placeholder='Enter a bucketlist item...' onChange={(event: any) => { setBucketListItem(event.target.value);}}
            style={{width: "100%", borderRadius: "8px", outline: "none", padding: "0.5em", border: "none"}}
          />
          <button className='add-bucket-list-item-button' onClick={addBucketListItem}>add item</button>
        </div>
      </div>
    </div>
  )
}
