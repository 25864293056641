export interface FirebaseImageData {
    publicImageUrl: string;
    filePath: string;
}

export function findImageInList(images: FirebaseImageData[], path: string) {
    path = path.toLowerCase().replace("images/", "");
    const item = images.find(i => i.filePath.toLowerCase().includes(path));
    if (item !== undefined) {
        // console.log("Found image:", item);
    }
    return item;
}